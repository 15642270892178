import http from '@/services/http-client';
import routes from '@/../config/env';

const { idm } = routes;

export const getEventSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getCompetitorSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};
export const getRegionSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getCompetitionSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getPlayerSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const approveEventMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-event`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveEventCompetitorMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-event-and-competitors`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createEventCompetitorMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-event-and-competitors`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveCompetitorMapping = (payload) => {
  const data = {
    competitorId: payload.competitorId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-competitor?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createCompetitorMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-competitor?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveRegionMapping = (payload) => {
  const data = {
    regionId: payload.regionId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-region`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createRegionMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-region`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveCompetitionMapping = (payload) => {
  const data = {
    competitionId: payload.competitionId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-competition`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createCompetitionMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-competition`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approvePlayerMapping = (payload) => {
  const data = {
    playerId: payload.playerId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-player?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createPlayerMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-player?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const unmapMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  let url;
  switch (payload.mappingType) {
  case 'EVENTS_MAPPING':
    data.eventId = payload.id;
    data.sport = payload.sport;
    url = 'event';
    break;
  case 'COMPETITORS_MAPPING':
    data.sport = payload.sport;
    url = 'competitor';
    break;
  case 'COMPETITIONS_MAPPING':
    data.competitionId = payload.id;
    url = 'competition';
    break;
  case 'REGIONS_MAPPING':
    data.regionId = payload.id;
    url = 'region';
    break;
  case 'PLAYERS_MAPPING':
    data.sport = payload.sport;
    url = 'player';
    break;
  default:
    break;
  }
  const options = {
    method: 'post',
    url: `${idm}/idm/revert-${url}-mapping`,
    params: data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};
